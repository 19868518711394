<template>
  <div class="page-login-desktop">
    <v-card flat>
      <v-card-title class="text-body-1 font-weight-bold d-flex align-center justify-space-between">
        ویرایش اطلاعات کاربر
        <v-btn @click="$router.back()" text color="main" large>
          بازگشت
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row justify="space-between">
          <v-col :cols="12" :lg="6">
            <v-row>

              <v-col :cols="12">
                <v-text-field outlined label="نام و نام خانوادگی" hide-details v-model="admin.Nameandfamily"/>
              </v-col>

              <v-col :cols="12">
                <v-text-field
                    disabled
                    readonly
                    outlined label="شماره موبایل" hide-details v-model="admin.mobile"/>
              </v-col>

              <v-col :cols="12">
                <v-text-field
                    outlined label="تعداد حساب مجاز" hide-details v-model="admin.LimitCount"/>
              </v-col>

              <v-col :cols="12">
                <v-select outlined label="نوع کاربر" hide-details v-model="admin.accountType" :items="adminTypes"
                          item-value="id" item-text="name"/>
              </v-col>

              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-text-field outlined label="شماره ثابت تماس" hide-details v-model="admin.phone"/>
              </v-col>

              <v-col v-if="admin.accountType === 1" :cols="12">
                <v-text-field disabled outlined label="شماره مدیر املاک" hide-details v-model="admin.ParentMobile"/>
              </v-col>

              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-text-field outlined label="نام آژانس" hide-details v-model="admin.Agencyname"/>
              </v-col>

              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-text-field outlined label="تعداد زیر مجموعه مجاز" hide-details
                              v-model="admin.Numberofaccountsallowed"/>
              </v-col>

              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-combobox
                    :items="locations"
                    item-text="name"
                    item-value="id"
                    outlined
                    label="محله"
                    hide-details
                    v-model="admin.Neighborhoodcode"/>
              </v-col>

              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-text-field outlined label="آدرس" hide-details v-model="admin.Adress"/>
              </v-col>
              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-switch inset label="وضعیت فعال بودن کاربر" hide-details v-model="admin.Userstatus"/>
              </v-col>
              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-switch inset label="دسترسی به نرم افزار ویندوز" hide-details
                          v-model="admin.AccesstoWindowssoftware"/>
              </v-col>
              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-switch inset label="دسترسی به نرم افزار موبایل" hide-details v-model="admin.Mobileaccess"/>
              </v-col>
              <v-col v-if="admin.accountType === 2" :cols="12">
                <v-switch inset label="دسترسی به سایت" hide-details v-model="admin.Siteaccess"/>
              </v-col>
            </v-row>
          </v-col>

          <v-col :cols="5">
            <h1 class="text-h6 mb-4">
              اطلاعات اشتراک:
            </h1>

            <div class="mb-4">
              <b class="font-weight-bold ">
                تاریخ خرید اشتراک :
              </b>
              <span>
                {{ admin.lastbuytime }}
              </span>
            </div>
            <div class="mb-4">
              <b>
                تعداد روز باقی مانده :
              </b>
              <span>
           {{ admin.remandedDay }}
              </span>
            </div>
            <div class="mb-4">
              <b>
                مناطق :
              </b>
              <div>
                <v-chip
                    class="ml-2"
                    small
                    color="primary"
                    v-for="(item,index) in admin.neighborhoods"
                    :key="index"
                >
                  {{ item }}
                </v-chip>
              </div>
            </div>


          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="admin.loading" @click="() => {
          admin.accountType === 1
            ? adminCreaterealestateconsultant()
            : adminCreatepropertymanager()
        
        }" elevation="0" large color="main" class="white--text">
          ثبت اطلاعات
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import {update, find} from "@Newfiling/module-user/src/api";
import {AppConfig} from "@Newfiling/Services";

export default {

  name: 'DesktopLogin',

  data() {
    return {
      admin: {
        LimitCount: 2,
        id: null,
        accountType: null,
        loading: false,
        mobile: "",
        Nameandfamily: "",
        Adress: "",
        Agencyname: "",
        Neighborhoodcode: "",
        phone: null,
        Userstatus: false,
        Toprealestate: false,
        AccesstoWindowssoftware: false,
        Mobileaccess: false,
        Siteaccess: false,
        Numberofaccountsallowed: null,
        ParentMobile: null,
        remandedDay: "",
        lastbuytime: "",
        neighborhoods: [],
      },
      adminTypes: [
        {
          id: 1,
          name: 'مشاور املاک'
        },
        {
          id: 2,
          name: 'مدیر املاک'
        },
      ],
      locations: [],
    }
  },

  beforeMount() {
    this.$store.commit('Breadcrumb/setBreadcrumb', [
      {
        text: 'پنل مدیریت نیوفایل',
        disabled: false,
        href: '/panel/dashboard',
      },
      {
        text: 'لیست کاربران',
        disabled: false,
        href: '/panel/users/list',
      },
      {
        text: 'کاربر جدید',
        disabled: true,
        href: '#',
      },
    ])
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfig = (await AppConfig) || {};
      this.locations = appConfig.neighborhoods.map(item => {
        return {
          name: item.NeighborhoodName,
          id: item.Id
        }
      });
      await this.fetchAdmin(this.$route.params.id);
    });
  },

  methods: {
    async adminCreatepropertymanager() {
      this.admin.loading = true;
      try {
        await update({
          id: this.admin.id,
          mobile: this.admin.mobile,
          Nameandfamily: this.admin.Nameandfamily,
          Adress: this.admin.Adress,
          Agencyname: this.admin.Agencyname,
          Neighborhoodcode: this.admin.Neighborhoodcode?.id || this.admin.Neighborhoodcode,
          phone: this.admin.phone,
          Userstatus: this.admin.Userstatus,
          Toprealestate: this.admin.Toprealestate,
          AccesstoWindowssoftware: this.admin.AccesstoWindowssoftware,
          Mobileaccess: this.admin.Mobileaccess,
          Siteaccess: this.admin.Siteaccess,
          Numberofaccountsallowed: this.admin.Numberofaccountsallowed,
          LimitCount: this.admin.LimitCount,
        })
        this.$toast.success('کاربر با موفقیت ویرایش شد.');
        await this.$router.replace('/panel/users/list')
      } catch (e) {
        console.log(e)
      }
      this.admin.loading = false
    },

    async adminCreaterealestateconsultant() {
      this.admin.loading = true;
      try {
        await update({
          id: this.admin.id,
          ParentMobile: this.admin.ParentMobile,
          Nameandfamily: this.admin.Nameandfamily,
          mobile: this.admin.mobile,
          LimitCount: this.admin.LimitCount,
        })
        this.$toast.success('کاربر با موفقیت ویرایش شد.');
        await this.$router.back();
      } catch (e) {
        console.log(e)
      }
      this.admin.loading = false
    },

    async fetchAdmin(id) {
      this.initLoading = true;
      try {
        const res = (await find(id))?.data?.message;
        if (res.accountType === 1) {
          this.admin = {
            ...this.admin,
            id: res.id,
            accountType: 2,
            LimitCount: res.limitCount,
            loading: false,
            mobile: res.mobile,
            Nameandfamily: res.nameandfamily,
            Adress: res.adress,
            Agencyname: res.agencyname,
            Neighborhoodcode: this.locations.find(i => Number(i.id) === Number(res.neighborhoodcode)) ?? {},
            phone: res.phone,
            Userstatus: res.userstatus,
            Toprealestate: res.toprealestate,
            AccesstoWindowssoftware: res.accesstoWindowssoftware,
            Mobileaccess: res.mobileaccess,
            Siteaccess: res.siteaccess,
            Numberofaccountsallowed: res.numberofaccountsallowed,
            ParentMobile: res.parentMobile,
            remandedDay: res.remandedDay,
            lastbuytime: res.lastbuytime,
            neighborhoods: (res.neighborhoods?.split('-') || [])
                .map(item => {
                  return this.locations.find(i => i.id === parseInt(item))?.name
                })
          }
        } else {
          this.admin = {
            ...this.admin,
            id: res.id,
            accountType: 1,
            loading: false,
            mobile: res.mobile,
            ParentMobile: res.parentMobile,
            Nameandfamily: res.nameandfamily,
          }
        }
      } catch (e) {
        console.log(e)
      }
      this.initLoading = false;
    },
  },

}
</script>
